import React from "react";

import { Button as SharedButton } from "@epc-front/shared/components/Button";

import { EventDataLayer, tracking } from "../../app/services/tracking";

type ButtonProps = React.ComponentProps<typeof SharedButton> & {
  dataLayer?: EventDataLayer;
};

export const Button = React.forwardRef(
  (props: ButtonProps, ref?: React.ForwardedRef<HTMLButtonElement>) => {
    const { dataLayer, onClick, ...restProps } = props;
    return (
      <SharedButton
        ref={ref}
        {...restProps}
        onClick={(event: unknown) => {
          onClick?.(event);

          if (dataLayer) {
            tracking.trackEvent(dataLayer);
          }
        }}
      />
    );
  },
);
