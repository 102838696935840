import React from "react";
import { Link, Location } from "react-router-dom";

type AProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "href"> & {
  blankLabel?: string;
  href: Partial<Location> | string;
  state?: { preventScrollReset: boolean };
};

export const A = React.forwardRef(
  (props: AProps, ref?: React.ForwardedRef<HTMLAnchorElement>) => {
    const {
      "aria-label": ariaLabel,
      blankLabel = "Nouvelle fenêtre",
      children,
      href,
      state,
      ...restProps
    } = props;

    if (typeof href === "string") {
      if (href.startsWith("http")) {
        return (
          <a
            ref={ref}
            {...restProps}
            aria-label={ariaLabel ? `${ariaLabel} - ${blankLabel}` : blankLabel}
            href={href}
            rel="noreferrer"
            target="_blank"
          >
            {children}
          </a>
        );
      }

      if (href.startsWith("mailto:") || href.startsWith("tel:")) {
        return (
          <a ref={ref} {...restProps} aria-label={ariaLabel} href={href}>
            {children}
          </a>
        );
      }
    }

    return (
      <Link
        ref={ref}
        {...restProps}
        aria-label={ariaLabel}
        state={state}
        to={href}
      >
        {children}
      </Link>
    );
  },
);
