import classNames from "classnames";
import React from "react";

const classNamePrefix = "Alert";

type AlertProps = React.HTMLAttributes<HTMLDivElement> & {
  status?: "error" | "info" | "success" | "warning";
};

export const Alert = React.forwardRef(
  (props: AlertProps, ref?: React.ForwardedRef<HTMLDivElement>) => {
    const { children, className, role = "alert", status, ...restProps } = props;
    return (
      <div
        ref={ref}
        {...restProps}
        className={classNames(className, classNamePrefix)}
        data-status={status}
        role={role}
      >
        <div>{children}</div>
      </div>
    );
  },
);
