import classNames from "classnames";
import React from "react";

const classNamePrefix = "Overlay";

type OverlayProps = React.HTMLAttributes<HTMLDivElement>;

export const Overlay = React.forwardRef(
  (props: OverlayProps, ref?: React.ForwardedRef<HTMLDivElement>) => {
    const { className, ...restProps } = props;
    return (
      <div
        ref={ref}
        {...restProps}
        className={classNames(className, classNamePrefix)}
      />
    );
  },
);
