import React from "react";

declare const window: Window & { grecaptcha?: any };

type ReCaptchaProps = { apiKey: string };

export const ReCaptcha = (props: ReCaptchaProps) => {
  const { apiKey } = props;

  React.useEffect(() => {
    const id = "ReCaptcha";

    if (!document.getElementById(id)) {
      const script = document.createElement("script");
      script.async = true;
      script.id = id;
      script.src = `https://www.google.com/recaptcha/api.js?render=${apiKey}`;
      document.head.insertBefore(script, document.head.childNodes[0]);
    }

    document.body.dataset.reCaptchaBadgeVisibility = "visible";
    return () => {
      delete document.body.dataset.reCaptchaBadgeVisibility;
    };
  }, [apiKey]);

  return null;
};
