export type Account = {
  birthDate?: string;
  civility: AccountCivility;
  consentSyndic?: AccountConsentSyndic;
  email: string;
  firmOffers?: AccountOffer;
  firstName: string;
  hasAcceptedDataUse?: boolean;
  hasAcceptedTermsOfService?: boolean;
  isMandatSyndic: boolean;
  lastName: string;
  partnersOffers?: AccountOffer;
  phoneNumbers: AccountPhoneNumbers;
  referenceActivation: string;
  status: AccountStatus;
};

export enum AccountCivility {
  MME = "MME",
  MR = "MR",
  MR_AND_MME = "MR_AND_MME",
  OTHER = "OTHER",
}

export enum AccountStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  UNCONFIRMED_AFTER_DELAY = "UNCONFIRMED_AFTER_DELAY",
  UNCONFIRMED_UNDER_DELAY = "UNCONFIRMED_UNDER_DELAY",
}

export type AccountConsentSyndic = {
  email: boolean;
  phone: boolean;
};

export type AccountOffer = {
  email: boolean;
  sms: boolean;
};

export type AccountPhoneNumbers = {
  business?: string;
  fixed?: string;
  mobile?: string;
};
