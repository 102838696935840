import { AcceptTermsOfServicePage } from "./pages/AcceptTermsOfServicePage";
import { AccessibilityPage } from "./pages/AccessibilityPage";
import { LegalNoticePage } from "./pages/LegalNoticePage";
import { SitemapPage } from "./pages/SitemapPage";
import { TermsOfServicePage } from "./pages/TermsOfServicePage";

export const AppRoutes = () => (
  <>
    <AcceptTermsOfServicePage />
    <AccessibilityPage />
    <LegalNoticePage />
    <SitemapPage />
    <TermsOfServicePage />
  </>
);
