import classNames from "classnames";
import React from "react";

const classNamePrefix = "ButtonContainer";

type ButtonContainerProps = React.HTMLAttributes<HTMLDListElement> & {
  action: React.ReactNode;
  label: React.ReactNode;
  variant?: "accept" | "download";
};

export const ButtonContainer = React.forwardRef(
  (props: ButtonContainerProps, ref?: React.ForwardedRef<HTMLDListElement>) => {
    const { action, children, className, label, variant, ...restProps } = props;
    return (
      <dl
        ref={ref}
        {...restProps}
        className={classNames(className, classNamePrefix)}
        data-variant={variant}
      >
        <dt className={`${classNamePrefix}-label`}>{label}</dt>
        <dd className={`${classNamePrefix}-action`}>{action}</dd>
        {children}
      </dl>
    );
  },
);
