import classNames from "classnames";
import React from "react";

const classNamePrefix = "Result";

type ResultProps = Omit<React.HTMLAttributes<HTMLDivElement>, "title"> & {
  action?: React.ReactNode;
  image?: React.ReactNode;
  text: React.ReactNode;
  title: React.ReactNode;
  titleAs?: "h1" | "h2" | "h3" | "h4" | "h5";
};

export const Result = React.forwardRef(
  (props: ResultProps, ref?: React.ForwardedRef<HTMLDivElement>) => {
    const {
      action,
      children,
      className,
      image,
      role = "alert",
      text,
      title,
      titleAs: Title = "h2",
      ...restProps
    } = props;
    return (
      <div
        ref={ref}
        {...restProps}
        className={classNames(className, classNamePrefix)}
        data-image={image ? true : undefined}
        role={role}
      >
        {image && <div className={`${classNamePrefix}-image`}>{image}</div>}
        <div className={`${classNamePrefix}-content`}>
          <Title className={`${classNamePrefix}-title`}>{title}</Title>
          <div className={`${classNamePrefix}-text`}>{text}</div>
          {children}
        </div>
        {action && <div className={`${classNamePrefix}-action`}>{action}</div>}
      </div>
    );
  },
);
