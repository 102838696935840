import React from "react";
import { useTranslation } from "react-i18next";

import { useGetHealthCheckQuery } from "../../app/api/public/app";
import { AlertDialog } from "../shared/AlertDialog";
import { Button } from "../shared/Button";

export const InformationNoticeDialog = () => {
  const {
    currentData: healthCheck = {
      informationNotice: { message: "", status: false },
    },
  } = useGetHealthCheckQuery();

  const [open, setOpen] = React.useState(false);
  React.useEffect(
    () => setOpen(healthCheck.informationNotice.status),
    [healthCheck.informationNotice.status],
  );

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <InformationNoticeDialogContent
        text={healthCheck.informationNotice.message}
      />
    </AlertDialog>
  );
};

type InformationNoticeDialogContentProps = { text: string };

const InformationNoticeDialogContent = (
  props: InformationNoticeDialogContentProps,
) => {
  const { text } = props;

  const { t } = useTranslation();
  const { cancel, title } = t(
    "features.informationNotice.InformationNoticeDialog",
  ) as unknown as { cancel: string; title: string };

  return (
    <AlertDialog.Content
      cancel={<Button variant="action">{cancel}</Button>}
      text={text}
      title={title}
      variant="information"
    />
  );
};
