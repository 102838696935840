import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { useLazyGetTermsOfServiceQuery } from "../app/api/public/legals";
import { router } from "../app/services/router";
import { Dialog } from "../features/shared/Dialog";
import { Loader } from "../features/shared/Loader";
import { Markdown } from "../features/shared/Markdown";

import { ReactComponent as Icon } from "./TermsOfServicePageIcon.svg";

export const TermsOfServicePage = () => {
  const hash = router.getTo("termsOfService");
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Dialog
      open={hash === location.hash}
      onOpenChange={(open) => {
        if (!open) {
          navigate({ ...location, hash: "" });
        }
      }}
    >
      <TermsOfServicePageContent
        trigger={
          document.querySelector(`[href*="${hash}"]`) as HTMLAnchorElement
        }
      />
    </Dialog>
  );
};

type TermsOfServicePageContentProps = { trigger: HTMLAnchorElement };

const TermsOfServicePageContent = (props: TermsOfServicePageContentProps) => {
  const { trigger } = props;

  const [getTermsOfService, { currentData: text, error, isFetching }] =
    useLazyGetTermsOfServiceQuery();

  const { t } = useTranslation();
  const { meta, title } = t("pages.TermsOfServicePage") as unknown as {
    meta: { title: string };
    title: string;
  };

  return (
    <Dialog.Content
      dataLayer={{
        categorie_page: "conditions_generales",
        nom_page: "conditions_generales",
        virtualpagetitle: "Conditions générales",
      }}
      icon={<Icon />}
      meta={meta}
      title={title}
      onCloseAutoFocus={() => trigger.focus()}
      onOpenAutoFocus={() => getTermsOfService(undefined, true)}
    >
      {text ? (
        <Markdown>{text}</Markdown>
      ) : (
        <Loader error={error} isFetching={isFetching} />
      )}
    </Dialog.Content>
  );
};
