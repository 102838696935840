import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { router } from "./app/services/router";
import { SignInPage } from "./pages/SignInPage";

const ForgotPasswordPage = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./pages/ForgotPasswordPage").then(
    (module) => ({
      default: module.ForgotPasswordPage,
    }),
  ),
);

export const AuthenticationAppRoutes = () => (
  <Routes>
    <Route element={<SignInPage />} index />
    <Route
      element={
        <React.Suspense fallback={null}>
          <ForgotPasswordPage />
        </React.Suspense>
      }
      path={router.getPath("authenticationForgotPassword")}
    />
    <Route
      element={<SignInPage />}
      path={router.getPath("authenticationSignUp")}
    />
    <Route element={<Navigate replace to="." />} path="*" />
  </Routes>
);
