import React from "react";

import { AlertDialog as SharedAlertDialog } from "@epc-front/shared/components/AlertDialog";

import { PageDataLayer, tracking } from "../../app/services/tracking";
import { Markdown } from "./Markdown";

import AccountDesktopImage from "./AlertDialogAccountDesktopImage.jpg";
import AccountMobileImage from "./AlertDialogAccountMobileImage.jpg";
import ContractsDesktopImage from "./AlertDialogContractsDesktopImage.jpg";
import ContractsMobileImage from "./AlertDialogContractsMobileImage.jpg";
import InformationDesktopImage from "./AlertDialogInformationDesktopImage.jpg";
import InformationMobileImage from "./AlertDialogInformationMobileImage.jpg";

type AlertDialogRootProps = React.ComponentProps<typeof SharedAlertDialog>;

const AlertDialogRoot = (props: AlertDialogRootProps) => (
  <SharedAlertDialog {...props} />
);

type AlertDialogContentProps = Omit<
  React.ComponentProps<typeof SharedAlertDialog.Content>,
  "image"
> & {
  dataLayer?: PageDataLayer;
  text: string;
  title: string;
  variant: "account" | "contracts" | "information";
};

const AlertDialogContent = React.forwardRef(
  (
    props: AlertDialogContentProps,
    ref?: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const { dataLayer, text, title, variant, onOpenAutoFocus, ...restProps } =
      props;

    const image = {
      account: { desktop: AccountDesktopImage, mobile: AccountMobileImage },
      contracts: {
        desktop: ContractsDesktopImage,
        mobile: ContractsMobileImage,
      },
      information: {
        desktop: InformationDesktopImage,
        mobile: InformationMobileImage,
      },
    }[variant];

    return (
      <SharedAlertDialog.Content
        ref={ref}
        {...restProps}
        data-variant={variant}
        image={
          <picture>
            <source media="(min-width: 960px)" srcSet={image.desktop} />
            <img alt="" src={image.mobile} />
          </picture>
        }
        text={<Markdown>{text}</Markdown>}
        title={<Markdown>{title}</Markdown>}
        onOpenAutoFocus={(event) => {
          onOpenAutoFocus?.(event);

          if (dataLayer) {
            tracking.trackPage(dataLayer);
          }
        }}
      />
    );
  },
);

export const AlertDialog = Object.assign(AlertDialogRoot, {
  Content: AlertDialogContent,
  Trigger: SharedAlertDialog.Trigger,
});
