import React from "react";
import { Link } from "react-router-dom";

import { router } from "../../app/services/router";
import { tracking } from "../../app/services/tracking";
import { Markdown } from "../shared/Markdown";
import { AccountSendActivationEmailForm } from "./AccountSendActivationEmailForm";
import { SignUpState, SignUpStateType } from "./SignUpState";

type SignUpMarkdownProps = {
  children: React.ReactNode;
  session?: { email?: string; username?: string };
  onChange: React.Dispatch<React.SetStateAction<SignUpState>>;
};

export const SignUpMarkdown = (props: SignUpMarkdownProps) => {
  const { children, session, onChange } = props;
  return (
    <Markdown
      options={{
        overrides: {
          AccountSendActivationEmailForm: {
            component: AccountSendActivationEmailForm,
            props: { value: session },
          },
          ForgotPasswordLink: {
            component: Link,
            props: { to: router.getTo("authenticationForgotPassword") },
          },
          GetAccountWithCharacterCodeLink: {
            component: "button",
            props: {
              type: "button",
              onClick: () => {
                onChange({
                  step: SignUpStateType.GET_ACCOUNT_WITH_CHARACTER_CODE,
                });

                tracking.trackEvent({
                  eventAction: "clic",
                  eventCategory: "pre-home",
                  eventLabel: "parcours_alternatif",
                });
              },
            },
          },
          SignInLink: { component: Link, props: { to: router.getTo("home") } },
        },
      }}
    >
      {children}
    </Markdown>
  );
};
