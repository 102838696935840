import React from "react";
import { Helmet, HelmetData } from "react-helmet-async";

const helmetData = new HelmetData({});

type HeadProps = { children: React.ReactNode };

export const Head = (props: HeadProps) => {
  const { children } = props;
  return <Helmet helmetData={helmetData}>{children}</Helmet>;
};
