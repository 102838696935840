import classNames from "classnames";
import React from "react";
import {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
} from "react-polymorphic-types";

const classNamePrefix = "Logo";

type LogoOwnProps = {};
const logoDefaultElement = "a";

export const Logo: PolymorphicForwardRefExoticComponent<
  LogoOwnProps,
  typeof logoDefaultElement
> = React.forwardRef(
  <TElementType extends React.ElementType = typeof logoDefaultElement>(
    props: PolymorphicPropsWithoutRef<LogoOwnProps, TElementType>,
    ref?: React.ForwardedRef<HTMLAnchorElement>,
  ) => {
    const { as: Tag = logoDefaultElement, className, ...restProps } = props;
    return (
      <Tag
        ref={ref}
        {...restProps}
        className={classNames(className, classNamePrefix)}
      />
    );
  },
);
