import React from "react";

import { A as SharedA } from "@epc-front/shared/components/A";

import { EventDataLayer, tracking } from "../../app/services/tracking";

type AProps = React.ComponentProps<typeof SharedA> & {
  dataLayer?: EventDataLayer;
};

export const A = React.forwardRef(
  (props: AProps, ref?: React.ForwardedRef<HTMLAnchorElement>) => {
    const { dataLayer, onClick, ...restProps } = props;
    return (
      <SharedA
        ref={ref}
        {...restProps}
        onClick={(event) => {
          onClick?.(event);

          if (dataLayer) {
            tracking.trackEvent(dataLayer);
          }
        }}
      />
    );
  },
);
