import React from "react";
import { useTranslation } from "react-i18next";

import { useSendActivationEmailMutation } from "../../app/api/public/account";
import { AlertDialog } from "../shared/AlertDialog";
import { Button } from "../shared/Button";

type AccountSendActivationEmailFormProps = {
  children: React.ReactNode;
  value: { email?: string; username?: string };
};

export const AccountSendActivationEmailForm = React.forwardRef(
  (
    props: AccountSendActivationEmailFormProps,
    ref?: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    const { children, value } = props;

    const [
      sendActivationEmail,
      { error, isLoading: isSubmitting, isSuccess: isSubmitSuccessful },
    ] = useSendActivationEmailMutation();

    const [open, setOpen] = React.useState(false);
    React.useEffect(() => {
      if (error || isSubmitSuccessful) {
        setOpen(true);
      }
    }, [error, isSubmitSuccessful]);

    const { t } = useTranslation();
    const { errorMessage, successMessage, title } = t(
      "features.account.AccountSendActivationEmailForm",
    ) as unknown as {
      errorMessage: { cancel: string; text: string };
      successMessage: { cancel: string; text: string };
      title: string;
    };

    const { cancel, text } = (() => {
      if (isSubmitSuccessful) {
        return {
          cancel: { text: successMessage.cancel },
          text: successMessage.text,
        };
      }

      if (error) {
        return {
          cancel: { text: errorMessage.cancel },
          text: errorMessage.text,
        };
      }

      return { cancel: { text: "" }, text: "" };
    })();

    return (
      <>
        <AlertDialog open={open} onOpenChange={setOpen}>
          <AlertDialog.Trigger>
            <Button
              ref={ref}
              dataLayer={{
                eventAction: "clic",
                eventCategory: "pre-home",
                eventLabel: "renvoi_confirmation_mail",
              }}
              disabled={isSubmitting}
              role={isSubmitting ? "status" : undefined}
              type="button"
              onClick={(event: any) => {
                event.preventDefault();
                sendActivationEmail(value);
              }}
            >
              {children}
            </Button>
          </AlertDialog.Trigger>
          <AlertDialog.Content
            cancel={
              <Button level="secondary" variant="action">
                {cancel.text}
              </Button>
            }
            text={text}
            title={title}
            variant="account"
          />
        </AlertDialog>
      </>
    );
  },
);
