import { Auth } from "@aws-amplify/auth";

const duration = 30 * 60;

function signOut() {
  (async () => {
    try {
      await Auth.signOut({ global: true });
    } catch {
      try {
        await Auth.signOut();
      } catch {}
    }
  })();
}

export const session = { duration, signOut };
