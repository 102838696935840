import React from "react";
import { useLocation } from "react-router-dom";

import store, { useAppSelector } from "../../app/store";
import { selectTheme, unsetTheme } from "../../app/store/theme";

export const Theme = () => {
  const theme = useAppSelector(selectTheme);
  React.useEffect(() => {
    const { style } = document.querySelector(":root") as HTMLElement;
    style.setProperty("--theme-background", theme?.background ?? null);
    style.setProperty("--theme-color", theme?.color ?? null);
  }, [theme]);

  const location = useLocation();
  React.useEffect(() => {
    if (location.pathname.replace(/^\/|\/$/g, "").split("/").length === 1) {
      store.dispatch(unsetTheme());
    }
  }, [location.pathname]);

  return null;
};
