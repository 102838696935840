import { AriaFieldProps, useField } from "@react-aria/label";
import classNames from "classnames";
import React from "react";

import { Field } from "./Field";

const classNamePrefix = "Select";

type SelectProps = AriaFieldProps & {
  children: React.ReactNode;
  className?: string;
  errorMessage?: React.ReactNode;
  isDisabled?: boolean;
  isRequired?: boolean;
  placeholder?: React.ReactNode;
  value?: string;
  onChange?: (value: string) => void;
};

export const Select = React.forwardRef(
  (props: SelectProps, ref: React.ForwardedRef<HTMLSelectElement>) => {
    const {
      children,
      className,
      description,
      errorMessage,
      isDisabled,
      isInvalid = !!props.errorMessage,
      isRequired,
      label,
      placeholder,
      value,
      onChange,
    } = props;

    const { descriptionProps, errorMessageProps, fieldProps, labelProps } =
      useField({ ...props, isInvalid });

    return (
      <Field className={classNames(className, classNamePrefix)}>
        {label && <Field.Label {...labelProps}>{label}</Field.Label>}
        <select
          ref={ref}
          {...fieldProps}
          aria-invalid={isInvalid ? true : undefined}
          aria-required={isRequired}
          disabled={isDisabled}
          value={value}
          onChange={(event) => onChange?.(event.target.value)}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {children}
        </select>
        {description && (
          <Field.Description {...descriptionProps}>
            {description}
          </Field.Description>
        )}
        {errorMessage && (
          <Field.ErrorMessage {...errorMessageProps}>
            {errorMessage}
          </Field.ErrorMessage>
        )}
      </Field>
    );
  },
);
