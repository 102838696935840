import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as yup from "yup";

import { useManualSignInMutation } from "../../app/api/public/auth";
import { HttpStatus } from "../../app/constants/HttpStatus";
import { AccountStatus } from "../../app/models/Account";
import { router } from "../../app/services/router";
import { Button } from "../shared/Button";
import { FieldController } from "../shared/FieldController";
import { Form } from "../shared/Form";
import { Markdown } from "../shared/Markdown";
import { PasswordField } from "../shared/PasswordField";
import { TextField } from "../shared/TextField";
import { AccountSendActivationEmailForm } from "./AccountSendActivationEmailForm";

export const AccountSignInForm = () => {
  const [signIn] = useManualSignInMutation();

  const { t } = useTranslation();
  const { errorMessage, fields, legend, submit } = t(
    "features.account.AccountSignInForm",
  ) as unknown as {
    errorMessage: Record<AccountStatus | HttpStatus | string, string>;
    fields: {
      email: { label: string; messages: { invalid: string; required: string } };
      password: {
        label: string;
        marker: string;
        messages: { required: string };
      };
    };
    legend: string;
    submit: string;
  };

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    getValues,
    setError,
  } = useForm({
    defaultValues: { email: "", password: "" },
    resolver: yupResolver(
      yup.object().shape({
        email: yup
          .string()
          .required(fields.email.messages.required)
          .email(fields.email.messages.invalid),
        password: yup.string().required(fields.password.messages.required),
      }),
    ),
  });

  return (
    <Form
      actions={
        <Button
          dataLayer={{
            eventAction: "clic",
            eventCategory: "pre_home",
            eventLabel: "se_connecter",
          }}
          fluid
          isSubmitting={isSubmitting}
          type="submit"
          variant="action"
        >
          {submit}
        </Button>
      }
      errorMessage={
        errors.root?.server.message && (
          <Markdown
            options={{
              overrides: {
                AccountSendActivationEmailForm: {
                  component: AccountSendActivationEmailForm,
                  props: { value: { email: getValues("email") } },
                },
                ForgotPasswordLink: {
                  component: Link,
                  props: { to: router.getTo("authenticationForgotPassword") },
                },
              },
            }}
          >
            {errors.root.server.message}
          </Markdown>
        )
      }
      name="AccountSignInForm"
      onSubmit={handleSubmit(async (values) => {
        try {
          await signIn(values).unwrap();
        } catch (_error) {
          const error = _error as { status: keyof typeof errorMessage };
          setError("root.server", {
            message:
              errorMessage[error.status] ??
              errorMessage[HttpStatus.INTERNAL_SERVER_ERROR],
          });
        }
      })}
    >
      <Form.Fieldset>
        <Form.Legend>{legend}</Form.Legend>
        <FieldController
          control={control}
          name="email"
          render={(controllerProps) => (
            <TextField
              {...controllerProps.field}
              autoComplete="username"
              isRequired
              label={fields.email.label}
              type="email"
            />
          )}
        />
        <FieldController
          control={control}
          name="password"
          render={(controllerProps) => (
            <PasswordField
              {...controllerProps.field}
              autoComplete="current-password"
              isRequired
              label={fields.password.label}
              markerLabel={fields.password.marker}
            />
          )}
        />
      </Form.Fieldset>
    </Form>
  );
};
