import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import {
  useGetAccountWith6CharacterCodeMutation,
  useGetAccountWith14CharacterCodeMutation,
} from "../../app/api/public/account";
import { HttpStatus } from "../../app/constants/HttpStatus";
import { AccountStatus } from "../../app/models/Account";
import { Button } from "../shared/Button";
import { FieldController } from "../shared/FieldController";
import { Form } from "../shared/Form";
import { TextField } from "../shared/TextField";
import { SignUpMarkdown } from "./SignUpMarkdown";
import { SignUpState, SignUpStateType } from "./SignUpState";

type SignUpGetAccountWithCharacterCodeFormProps = {
  length: 6 | 14;
  onChange: React.Dispatch<React.SetStateAction<SignUpState>>;
};

export const SignUpGetAccountWithCharacterCodeForm = (
  props: SignUpGetAccountWithCharacterCodeFormProps,
) => {
  const { length, onChange } = props;

  const useGetAccountWithCharacterCodeMutation = {
    6: useGetAccountWith6CharacterCodeMutation,
    14: useGetAccountWith14CharacterCodeMutation,
  }[length];
  const [getAccountWithCharacterCode] =
    useGetAccountWithCharacterCodeMutation();

  const { t } = useTranslation();
  const { errorMessage, fields, legend, submit } = t(
    "features.account.SignUpGetAccountWithCharacterCodeForm",
    { length, context: length },
  ) as unknown as {
    errorMessage: Record<AccountStatus | HttpStatus, string>;
    fields: {
      code: {
        label: string;
        messages: { invalid: string; matches: string; required: string };
      };
    };
    legend: string;
    submit: string;
  };

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
  } = useForm({
    defaultValues: { code: "", length },
    resolver: yupResolver(
      yup.object().shape({
        code: yup
          .string()
          .required(fields.code.messages.required)
          .when("length", {
            is: 6,
            then: (schema) =>
              schema
                .matches(/^\d+$/, fields.code.messages.matches)
                .length(6, fields.code.messages.invalid),
            otherwise: (schema) =>
              schema.test(
                "length",
                fields.code.messages.invalid,
                (code) => code.replace(/\s+/g, "").length === 14,
              ),
          }),
        length: yup.number(),
      }),
    ),
  });

  return (
    <Form
      actions={
        <Button
          dataLayer={{
            eventAction: "clic",
            eventCategory: "pre-home",
            eventLabel: `code-${length}`,
          }}
          fluid
          isSubmitting={isSubmitting}
          type="submit"
          variant="action"
        >
          {submit}
        </Button>
      }
      errorMessage={
        errors.root?.server.message && (
          <SignUpMarkdown onChange={onChange}>
            {errors.root.server.message}
          </SignUpMarkdown>
        )
      }
      name={`SignUpGetAccountWith${length}CharacterCodeForm`}
      onSubmit={handleSubmit(async (values) => {
        try {
          const account = await getAccountWithCharacterCode(values).unwrap();
          if (account.status === AccountStatus.INACTIVE) {
            onChange({ step: SignUpStateType.CONFIRM_ACCOUNT, account });
          } else {
            onChange({
              step: SignUpStateType.RESULT,
              message: errorMessage[account.status],
              session: { email: account.email },
            });
          }
        } catch (_error) {
          const error = _error as { status: keyof typeof errorMessage };
          if (
            error.status === HttpStatus.ENHANCE_YOUR_CALM ||
            error.status === HttpStatus.MISDIRECTED_REQUEST ||
            error.status === HttpStatus.NOT_FOUND
          ) {
            setError("code", { message: errorMessage[error.status] });
          } else {
            setError("root.server", {
              message: errorMessage[HttpStatus.INTERNAL_SERVER_ERROR],
            });
          }
        }
      })}
    >
      <Form.Fieldset>
        <Form.Legend>{legend}</Form.Legend>
        <FieldController
          control={control}
          name="code"
          render={(controllerProps) => (
            <TextField
              {...controllerProps.field}
              errorMessage={
                controllerProps.field.errorMessage && (
                  <SignUpMarkdown onChange={onChange}>
                    {controllerProps.field.errorMessage}
                  </SignUpMarkdown>
                )
              }
              isRequired
              label={fields.code.label}
              type="text"
            />
          )}
        />
      </Form.Fieldset>
    </Form>
  );
};
