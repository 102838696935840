export enum HttpStatus {
  BAD_REQUEST = 400,
  ENHANCE_YOUR_CALM = 420,
  INTERNAL_SERVER_ERROR = 500,
  MISDIRECTED_REQUEST = 421,
  NO_CONTENT = 204,
  NOT_FOUND = 404,
  OK = 200,
  SERVICE_UNAVAILABLE = 503,
}
