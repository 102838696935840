import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as yup from "yup";

import { HttpStatus } from "../../app/constants/HttpStatus";
import {
  Account,
  AccountCivility,
  AccountConsentSyndic,
  AccountOffer,
  AccountPhoneNumbers,
  AccountStatus,
} from "../../app/models/Account";
import { router } from "../../app/services/router";
import { Button } from "../shared/Button";
import { Checkbox } from "../shared/Checkbox";
import { CheckboxGroup } from "../shared/CheckboxGroup";
import { DateField, isValidDate } from "../shared/DateField";
import { FieldController } from "../shared/FieldController";
import { FieldGroup } from "../shared/FieldGroup";
import { Form } from "../shared/Form";
import { Markdown } from "../shared/Markdown";
import { PasswordField } from "../shared/PasswordField";
import { PhoneField, isValidPhoneNumber } from "../shared/PhoneField";
import { Select } from "../shared/Select";
import { TextField } from "../shared/TextField";

import { ReactComponent as ContactDetailsIcon } from "./AccountFormContactDetailsIcon.svg";
import { ReactComponent as SubscriptionsIcon } from "./AccountFormSubscriptionsIcon.svg";

type AccountFormProps = {
  value: Account;
  onSubmit: (values: Account) => Promise<void>;
};

export const AccountForm = (props: AccountFormProps) => {
  const { value, onSubmit, ...restProps } = props;

  const active = value.status === AccountStatus.ACTIVE;

  const { t } = useTranslation();
  const { errorMessage, fields, fieldSets, submit, successMessage } = t(
    "features.account.AccountForm",
    { context: active && "active" },
  ) as unknown as {
    errorMessage: Record<HttpStatus, string>;
    fields: {
      birthDate: {
        label: string;
        messages: { invalid: string; required: string };
        placeholder: string;
      };
      civility: {
        label: string;
        messages: { required: string };
        option: Record<AccountCivility, string>;
        placeholder: string;
      };
      confirmPassword: {
        label: string;
        marker: string;
        messages: { mismatch: string; required: string };
      };
      consentSyndic: {
        fields: Record<keyof AccountConsentSyndic, { label: string }>;
      };
      email: {
        description: string;
        label: string;
        messages: { invalid: string; required: string };
      };
      firmOffers: {
        description: string;
        label: string;
        option: Record<keyof AccountOffer, string>;
      };
      hasAcceptedDataUse: { label: string; messages: { required: string } };
      hasAcceptedTermsOfService: {
        label: string;
        messages: { required: string };
      };
      name: {
        fields: {
          firstName: {
            label: string;
            messages: { matches: string; required: string };
          };
          lastName: {
            label: string;
            messages: { matches: string; required: string };
          };
        };
      };
      partnersOffers: {
        label: string;
        option: Record<keyof AccountOffer, string>;
      };
      password: {
        description: string;
        label: string;
        marker: string;
        messages: { invalid: string; required: string };
      };
      phoneNumbers: {
        fields: Record<
          keyof AccountPhoneNumbers,
          { label: string; messages: { invalid: string } }
        >;
        label: string;
        messages: { required: string };
      };
    };
    fieldSets: {
      contactDetails: { legend: string };
      subscriptions: { legend: string };
    };
    submit: string;
    successMessage: string;
  };

  const {
    control,
    formState: { errors, isSubmitSuccessful, isSubmitted, isSubmitting },
    handleSubmit,
    reset,
    setError,
    trigger,
  } = useForm({
    defaultValues: {
      birthDate: "",
      civility: "" as AccountCivility,
      consentSyndic: { email: false, phone: false } as AccountConsentSyndic,
      email: "",
      firmOffers: [] as (keyof AccountOffer)[],
      firstName: "",
      isMandatSyndic: false,
      lastName: "",
      partnersOffers: [] as (keyof AccountOffer)[],
      phoneNumbers: {
        business: "",
        fixed: "",
        mobile: "",
      } as AccountPhoneNumbers,
      referenceActivation: "",
      status: "" as AccountStatus,
      confirmPassword: active ? undefined : "",
      hasAcceptedDataUse: active ? undefined : false,
      hasAcceptedTermsOfService: active ? undefined : false,
      password: active ? undefined : "",
    },
    resolver: yupResolver(
      yup.object().shape({
        birthDate: yup
          .string()
          .test((birthDate, { createError }) =>
            value.birthDate && !birthDate
              ? createError({ message: fields.birthDate.messages.required })
              : true,
          )
          .test((birthDate, { createError }) =>
            birthDate &&
            (!isValidDate(birthDate) ||
              new Date(birthDate).getTime() > new Date().getTime())
              ? createError({ message: fields.birthDate.messages.invalid })
              : true,
          ),
        civility: yup.string().required(fields.civility.messages.required),
        consentSyndic: yup.object({
          email: yup.boolean(),
          phone: yup.boolean(),
        }),
        email: yup
          .string()
          .required(fields.email.messages.required)
          .email(fields.email.messages.invalid),
        firmOffers: yup.array().required(),
        firstName: yup.string().when("status", {
          is: AccountStatus.INACTIVE,
          then: (schema) =>
            schema
              .required(fields.name.fields.firstName.messages.required)
              .matches(
                // @ts-ignore
                /^\p{L}+(?:[-' ]\p{L}+)*$/u,
                fields.name.fields.firstName.messages.matches,
              ),
        }),
        isMandatSyndic: yup.boolean(),
        lastName: yup.string().when("status", {
          is: AccountStatus.INACTIVE,
          then: (schema) =>
            schema
              .required(fields.name.fields.lastName.messages.required)
              .matches(
                // @ts-ignore
                /^\p{L}+(?:[-' ]\p{L}+)*$/u,
                fields.name.fields.lastName.messages.matches,
              ),
        }),
        partnersOffers: yup.array().required(),
        phoneNumbers: yup
          .object(
            (
              ["mobile", "fixed", "business"] as (keyof AccountPhoneNumbers)[]
            ).reduce(
              (phoneNumbersAccumulator, key) => ({
                ...phoneNumbersAccumulator,
                [key]: yup
                  .string()
                  .test(
                    "invalid",
                    fields.phoneNumbers.fields[key].messages.invalid,
                    (phoneNumber) =>
                      phoneNumber ? isValidPhoneNumber(phoneNumber) : true,
                  ),
              }),
              {},
            ),
          )
          .test((phoneNumbers, { createError }) =>
            Object.values(phoneNumbers).every((phoneNumber) => !phoneNumber)
              ? createError({
                  message: fields.phoneNumbers.messages.required,
                  path: "phoneNumbers.root",
                })
              : true,
          ),
        referenceActivation: yup.string(),
        status: yup.string(),
        confirmPassword: active
          ? yup.string().optional()
          : yup
              .string()
              .required(fields.confirmPassword.messages.required)
              .oneOf(
                [yup.ref("password")],
                fields.confirmPassword.messages.mismatch,
              ),
        hasAcceptedDataUse: active
          ? yup.boolean().optional()
          : yup
              .boolean()
              .oneOf([true], fields.hasAcceptedDataUse.messages.required),
        hasAcceptedTermsOfService: active
          ? yup.boolean().optional()
          : yup
              .boolean()
              .oneOf(
                [true],
                fields.hasAcceptedTermsOfService.messages.required,
              ),
        password: active
          ? yup.string().optional()
          : yup
              .string()
              .required(fields.password.messages.required)
              .matches(
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-]).{8,}$/,
                fields.password.messages.invalid,
              ),
      }),
    ),
  });
  React.useEffect(() => {
    reset(
      {
        ...value,
        consentSyndic: { email: false, phone: false, ...value.consentSyndic },
        firmOffers: value.firmOffers
          ? (
              Object.entries(value.firmOffers) as [
                keyof AccountOffer,
                boolean,
              ][]
            ).reduce(
              (valueAccumulator, [key, firmOffer]) =>
                firmOffer ? [...valueAccumulator, key] : valueAccumulator,
              [] as (keyof AccountOffer)[],
            )
          : [],
        partnersOffers: value.partnersOffers
          ? (
              Object.entries(value.partnersOffers) as [
                keyof AccountOffer,
                boolean,
              ][]
            ).reduce(
              (valueAccumulator, [key, firmOffer]) =>
                firmOffer ? [...valueAccumulator, key] : valueAccumulator,
              [] as (keyof AccountOffer)[],
            )
          : [],
        phoneNumbers: value.phoneNumbers
          ? (
              ["mobile", "fixed", "business"] as (keyof AccountPhoneNumbers)[]
            ).reduce(
              (valueAccumulator, key) => ({
                ...valueAccumulator,
                [key]: value.phoneNumbers[key]
                  ? isValidPhoneNumber(value.phoneNumbers[key])
                    ? value.phoneNumbers[key]
                    : ""
                  : "",
              }),
              {},
            )
          : {},
      },
      { keepIsSubmitSuccessful: true },
    );
  }, [reset, value]);

  return (
    <Form
      {...restProps}
      actions={
        <Button
          dataLayer={
            !active && {
              eventAction: "clic",
              eventCategory: "pre-home",
              eventLabel: "formulaire-creation-compte",
            }
          }
          isSubmitting={isSubmitting}
          type="submit"
          variant="action"
        >
          {submit}
        </Button>
      }
      errorMessage={errors.root?.server.message}
      name="AccountForm"
      successMessage={isSubmitSuccessful && successMessage}
      onSubmit={handleSubmit(async (values) => {
        try {
          await onSubmit({
            ...values,
            firmOffers: values.firmOffers.reduce(
              (valueAccumulator, option) => ({
                ...valueAccumulator,
                [option]: true,
              }),
              { email: false, sms: false },
            ),
            partnersOffers: values.partnersOffers.reduce(
              (valueAccumulator, option) => ({
                ...valueAccumulator,
                [option]: true,
              }),
              { email: false, sms: false },
            ),
          } as any);
        } catch (_error) {
          const error = _error as { status: keyof typeof errorMessage };
          if (
            error.status === HttpStatus.BAD_REQUEST ||
            error.status === HttpStatus.ENHANCE_YOUR_CALM
          ) {
            setError(
              "email",
              { message: errorMessage[error.status] },
              { shouldFocus: true },
            );
          } else {
            setError("root.server", {
              message: errorMessage[HttpStatus.INTERNAL_SERVER_ERROR],
            });
          }
        }
      })}
    >
      <Form.Fieldset>
        <Form.Legend icon={<ContactDetailsIcon />}>
          {fieldSets.contactDetails.legend}
        </Form.Legend>
        <FieldGroup columnCount={2}>
          <FieldController
            control={control}
            name="civility"
            render={(controllerProps) => (
              <Select
                {...controllerProps.field}
                label={fields.civility.label}
                placeholder={fields.civility.placeholder}
              >
                {[
                  AccountCivility.MME,
                  AccountCivility.MR,
                  AccountCivility.MR_AND_MME,
                  AccountCivility.OTHER,
                ].map((civility) => (
                  <option key={civility} value={civility}>
                    {fields.civility.option[civility]}
                  </option>
                ))}
              </Select>
            )}
          />
        </FieldGroup>
        <FieldGroup columnCount={2}>
          <FieldController
            control={control}
            name="firstName"
            render={(controllerProps) => (
              <TextField
                {...controllerProps.field}
                isReadOnly={active}
                isRequired
                label={fields.name.fields.firstName.label}
                type="text"
              />
            )}
          />
          <FieldController
            control={control}
            name="lastName"
            render={(controllerProps) => (
              <TextField
                {...controllerProps.field}
                isReadOnly={active}
                isRequired
                label={fields.name.fields.lastName.label}
                type="text"
              />
            )}
          />
        </FieldGroup>
        <FieldGroup columnCount={2}>
          <FieldController
            control={control}
            name="email"
            render={(controllerProps) => (
              <TextField
                {...controllerProps.field}
                description={
                  active &&
                  controllerProps.field.value !== value.email &&
                  fields.email.description
                }
                isRequired
                label={fields.email.label}
                type="email"
              />
            )}
          />
          <FieldController
            control={control}
            name="birthDate"
            render={(controllerProps) => (
              <DateField
                {...controllerProps.field}
                isRequired={!!value.birthDate}
                label={fields.birthDate.label}
                placeholder={fields.birthDate.placeholder}
                type="date"
              />
            )}
          />
        </FieldGroup>
        {value.isMandatSyndic && (
          <FieldController
            control={control}
            name="consentSyndic.email"
            render={(controllerProps) => (
              <Checkbox
                {...controllerProps.field}
                isSelected={controllerProps.field.value}
                label={fields.consentSyndic.fields.email.label}
                value=""
              />
            )}
          />
        )}
        {!active && (
          <FieldGroup columnCount={2}>
            <FieldController
              control={control}
              name="password"
              render={(controllerProps) => (
                <PasswordField
                  {...controllerProps.field}
                  autoComplete="new-password"
                  description={fields.password.description}
                  isRequired
                  label={fields.password.label}
                  markerLabel={fields.password.marker}
                />
              )}
            />
            <FieldController
              control={control}
              name="confirmPassword"
              render={(controllerProps) => (
                <PasswordField
                  {...controllerProps.field}
                  autoComplete="new-password"
                  isRequired
                  label={fields.confirmPassword.label}
                  markerLabel={fields.confirmPassword.marker}
                />
              )}
            />
          </FieldGroup>
        )}
        <FieldGroup
          columnCount={2}
          errorMessage={isSubmitted && errors.phoneNumbers?.root?.message}
          label={fields.phoneNumbers.label}
        >
          {(
            ["mobile", "fixed", "business"] as (keyof AccountPhoneNumbers)[]
          ).map((key) => (
            <FieldController
              key={key}
              control={control}
              name={`phoneNumbers.${key}` as any}
              render={(controllerProps) => (
                <PhoneField
                  {...controllerProps.field}
                  errorMessage={
                    isSubmitted && controllerProps.field.errorMessage
                  }
                  label={fields.phoneNumbers.fields[key].label}
                  onChange={(nextPhoneNumber) => {
                    controllerProps.field.onChange(nextPhoneNumber);
                    trigger("phoneNumbers");
                  }}
                />
              )}
            />
          ))}
        </FieldGroup>
        {value.isMandatSyndic && (
          <FieldController
            control={control}
            name="consentSyndic.phone"
            render={(controllerProps) => (
              <Checkbox
                {...controllerProps.field}
                isSelected={controllerProps.field.value}
                label={fields.consentSyndic.fields.phone.label}
                value=""
              />
            )}
          />
        )}
      </Form.Fieldset>
      {(!active
        ? !(
            ["firmOffers", "partnersOffers"] as (
              | "firmOffers"
              | "partnersOffers"
            )[]
          ).some((name) =>
            (["email", "sms"] as (keyof AccountOffer)[]).some(
              (option) => value[name]?.[option],
            ),
          )
        : true) && (
        <Form.Fieldset>
          <Form.Legend icon={<SubscriptionsIcon />}>
            {fieldSets.subscriptions.legend}
          </Form.Legend>
          <FieldController
            control={control}
            name="firmOffers"
            render={(controllerProps) => (
              <CheckboxGroup
                {...controllerProps.field}
                description={fields.firmOffers.description}
                label={fields.firmOffers.label}
              >
                {(["email", "sms"] as (keyof AccountOffer)[]).map((key) => (
                  <CheckboxGroup.Item key={key} value={key}>
                    {fields.firmOffers.option[key]}
                  </CheckboxGroup.Item>
                ))}
              </CheckboxGroup>
            )}
          />
          <FieldController
            control={control}
            name="partnersOffers"
            render={(controllerProps) => (
              <CheckboxGroup
                {...controllerProps.field}
                label={fields.partnersOffers.label}
              >
                {(["email", "sms"] as (keyof AccountOffer)[]).map((key) => (
                  <CheckboxGroup.Item key={key} value={key}>
                    {fields.partnersOffers.option[key]}
                  </CheckboxGroup.Item>
                ))}
              </CheckboxGroup>
            )}
          />
        </Form.Fieldset>
      )}
      {!active && (
        <Form.Fieldset>
          <FieldController
            control={control}
            name="hasAcceptedDataUse"
            render={(controllerProps) => (
              <Checkbox
                {...controllerProps.field}
                isSelected={controllerProps.field.value}
                label={fields.hasAcceptedDataUse.label}
                value=""
              />
            )}
          />
          <FieldController
            control={control}
            name="hasAcceptedTermsOfService"
            render={(controllerProps) => (
              <Checkbox
                {...controllerProps.field}
                isSelected={controllerProps.field.value}
                label={
                  <Markdown
                    options={{
                      overrides: {
                        TermsOfServiceLink: {
                          component: Link,
                          props: { to: router.getTo("termsOfService") },
                        },
                      },
                    }}
                  >
                    {fields.hasAcceptedTermsOfService.label}
                  </Markdown>
                }
                value=""
              />
            )}
          />
        </Form.Fieldset>
      )}
    </Form>
  );
};
