import React from "react";

import { Dialog as SharedDialog } from "@epc-front/shared/components/Dialog";

import { PageDataLayer, tracking } from "../../app/services/tracking";
import { Head } from "./Head";
import { Markdown } from "./Markdown";

import AccountDesktopImage from "./DialogAccountDesktopImage.jpg";
import AccountMobileImage from "./DialogAccountMobileImage.jpg";
import ContractsDesktopImage from "./DialogContractsDesktopImage.jpg";
import ContractsMobileImage from "./DialogContractsMobileImage.jpg";
import InformationDesktopImage from "./DialogInformationDesktopImage.jpg";
import InformationMobileImage from "./DialogInformationMobileImage.jpg";

type DialogRootProps = React.ComponentProps<typeof SharedDialog>;

const DialogRoot = (props: DialogRootProps) => <SharedDialog {...props} />;

type DialogContentProps = Omit<
  React.ComponentProps<typeof SharedDialog.Content>,
  "image"
> & {
  dataLayer?: PageDataLayer;
  meta?: { title: string };
  title: string;
  variant?: "account" | "contracts" | "information";
};

const DialogContent = React.forwardRef(
  (props: DialogContentProps, ref?: React.ForwardedRef<HTMLDivElement>) => {
    const {
      children,
      dataLayer,
      meta,
      title,
      variant,
      onOpenAutoFocus,
      ...restProps
    } = props;

    const image =
      variant &&
      {
        account: { desktop: AccountDesktopImage, mobile: AccountMobileImage },
        contracts: {
          desktop: ContractsDesktopImage,
          mobile: ContractsMobileImage,
        },
        information: {
          desktop: InformationDesktopImage,
          mobile: InformationMobileImage,
        },
      }[variant];

    return (
      <SharedDialog.Content
        ref={ref}
        {...restProps}
        data-variant={variant}
        image={
          image && (
            <picture>
              <source media="(min-width: 960px)" srcSet={image.desktop} />
              <img alt="" src={image.mobile} />
            </picture>
          )
        }
        title={<Markdown>{title}</Markdown>}
        onOpenAutoFocus={(event) => {
          onOpenAutoFocus?.(event);

          if (dataLayer) {
            tracking.trackPage(dataLayer);
          }
        }}
      >
        {meta && <Head {...meta} />}
        {children}
      </SharedDialog.Content>
    );
  },
);

export const Dialog = Object.assign(DialogRoot, {
  Content: DialogContent,
  Trigger: SharedDialog.Trigger,
});
