import React from "react";

import { PageDataLayer, tracking } from "../../app/services/tracking";
import { Head } from "../../features/shared/Head";

const classNamePrefix = "AuthenticationPage";

type AuthenticationPageProps = {
  children: React.ReactNode;
  dataLayer: PageDataLayer;
  meta: { title: string };
  title: React.ReactNode;
};

export const AuthenticationPage = (props: AuthenticationPageProps) => {
  const { children, dataLayer, meta, title } = props;

  React.useEffect(() => tracking.trackPage(dataLayer), [dataLayer]);

  return (
    <>
      <Head {...meta} />
      <div className={classNamePrefix}>
        <h1 className={`${classNamePrefix}-title`}>{title}</h1>
        {children}
      </div>
    </>
  );
};
