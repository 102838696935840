import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { RouteKey } from "../../app/config/routes";
import { router } from "../../app/services/router";
import { A } from "../shared/A";
import { Button } from "../shared/Button";

import "./AppendicesNav.scss";

declare const window: Window & { Didomi?: any };

const classNamePrefix = "AppendicesNav";

export const AppendicesNav = () => {
  const location = useLocation();

  const { t } = useTranslation();
  const { label, links, tracking } = t(
    "features.core.AppendicesNav",
  ) as unknown as {
    label: string;
    links: {
      key:
        | "accessibility"
        | "fraudAlert"
        | "legalNotice"
        | "protectionPolicy"
        | "sitemap"
        | "termsOfService";
      href?: string;
      text: string;
    }[];
    tracking: { text: string };
  };

  const additionalLinksProps = {
    accessibility: { eventLabel: "accessibilite" },
    fraudAlert: { eventLabel: "alerte_fraude" },
    legalNotice: { eventLabel: "mentions_legales" },
    protectionPolicy: { eventLabel: "politique_de_protection_des_donnees" },
    sitemap: { eventLabel: "plan_du_site" },
    termsOfService: { eventLabel: "conditions_generales" },
  };

  return (
    <nav aria-label={label} className={classNamePrefix}>
      <ul>
        <li>
          <Button
            dataLayer={{
              eventAction: "clic",
              eventCategory: "footer",
              eventLabel: "gestion_cookies",
            }}
            type="button"
            onClick={() => window.Didomi?.preferences.show()}
          >
            {tracking.text}
          </Button>
        </li>
        {links.map((link) => {
          const additionalLinkProps = additionalLinksProps[link.key];
          return (
            <li key={link.key}>
              <A
                dataLayer={{
                  eventAction: "clic",
                  eventCategory: "footer",
                  eventLabel: additionalLinkProps.eventLabel,
                }}
                href={
                  link.href
                    ? link.href
                    : { ...location, hash: router.getTo(link.key as RouteKey) }
                }
              >
                {link.text}
              </A>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
