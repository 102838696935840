import React from "react";

import { Form as SharedForm } from "@epc-front/shared/components/Form";

import { Alert } from "./Alert";

type FormRootProps = React.ComponentProps<typeof SharedForm> & {
  actions: React.ReactNode;
  errorMessage?: React.ReactNode;
  reminder?: React.ReactNode;
  successMessage?: React.ReactNode;
};

const FormRoot = React.forwardRef(
  (props: FormRootProps, ref?: React.ForwardedRef<HTMLFormElement>) => {
    const {
      actions,
      children,
      errorMessage,
      reminder,
      successMessage,
      ...restProps
    } = props;
    return (
      <SharedForm ref={ref} {...restProps}>
        {children}
        {errorMessage && <Alert status="error">{errorMessage}</Alert>}
        {successMessage && <Alert status="success">{successMessage}</Alert>}
        {reminder && <SharedForm.Reminder>{reminder}</SharedForm.Reminder>}
        <SharedForm.Actions>{actions}</SharedForm.Actions>
      </SharedForm>
    );
  },
);

export const Form = Object.assign(FormRoot, {
  Fieldset: SharedForm.Fieldset,
  Legend: SharedForm.Legend,
});
