import { useTranslation } from "react-i18next";

import { HttpStatus } from "../app/constants/HttpStatus";
import { Button } from "../features/shared/Button";
import { Result } from "../features/shared/Result";

export const Error500Page = () => {
  const { t } = useTranslation();
  const { action, text, title } = t("pages.Error500Page") as unknown as {
    action: { text: string };
    text: string;
    title: string;
  };

  return (
    <Result
      action={
        <Button
          type="button"
          variant="action"
          onClick={() => window.location.reload()}
        >
          {action.text}
        </Button>
      }
      status={HttpStatus.INTERNAL_SERVER_ERROR}
      text={text}
      title={title}
    />
  );
};
