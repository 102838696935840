import { AriaTextFieldOptions, useTextField } from "@react-aria/textfield";
import { useObjectRef } from "@react-aria/utils";
import classNames from "classnames";
import React from "react";
import PhoneNumberInput, { Value } from "react-phone-number-input";

import { Field } from "./Field";
import labels from "./PhoneField.fr.json";

export { isPossiblePhoneNumber as isValidPhoneNumber } from "react-phone-number-input";

const classNamePrefix = "PhoneField";

type PhoneFieldProps = AriaTextFieldOptions<"input"> & {
  className?: string;
  errorMessage?: React.ReactNode;
};

export const PhoneField = React.forwardRef(
  (props: PhoneFieldProps, _ref: React.ForwardedRef<HTMLInputElement>) => {
    const {
      className,
      description,
      errorMessage,
      isInvalid = !!props.errorMessage,
      label,
    } = props;

    const ref = useObjectRef(_ref);
    const { descriptionProps, errorMessageProps, inputProps, labelProps } =
      useTextField({ ...props, isInvalid }, ref);

    return (
      <Field className={classNames(className, classNamePrefix)}>
        {label && <Field.Label {...labelProps}>{label}</Field.Label>}
        <PhoneNumberInput
          ref={ref as any}
          {...inputProps}
          defaultCountry="FR"
          flagUrl="/assets/flags/{XX}.svg"
          international
          labels={labels}
          limitMaxLength
          value={inputProps.value as Value}
          onChange={(value: any) =>
            inputProps.onChange?.({ target: { value } } as any)
          }
        />
        {description && (
          <Field.Description {...descriptionProps}>
            {description}
          </Field.Description>
        )}
        {errorMessage && (
          <Field.ErrorMessage {...errorMessageProps}>
            {errorMessage}
          </Field.ErrorMessage>
        )}
      </Field>
    );
  },
);
