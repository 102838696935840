import { AriaTextFieldOptions, useTextField } from "@react-aria/textfield";
import { useObjectRef } from "@react-aria/utils";
import classNames from "classnames";
import React from "react";

import { Field } from "./Field";

export function isValidDate(value: string) {
  return (
    /(\d{4})-(\d{2})-(\d{2})/.test(value) &&
    new Date(value).toString() !== "Invalid Date"
  );
}

const classNamePrefix = "DateField";

type DateFieldProps = AriaTextFieldOptions<"input"> & {
  className?: string;
  errorMessage?: React.ReactNode;
  max?: string;
  min?: string;
};

export const DateField = React.forwardRef(
  (props: DateFieldProps, _ref: React.ForwardedRef<HTMLInputElement>) => {
    const {
      className,
      description,
      errorMessage,
      isInvalid = !!props.errorMessage,
      label,
      max,
      min,
    } = props;

    const ref = useObjectRef(_ref);
    const { descriptionProps, errorMessageProps, inputProps, labelProps } =
      useTextField({ ...props, isInvalid }, ref);

    return (
      <Field className={classNames(className, classNamePrefix)}>
        {label && <Field.Label {...labelProps}>{label}</Field.Label>}
        <input ref={ref} {...inputProps} max={max} min={min} />
        {description && (
          <Field.Description {...descriptionProps}>
            {description}
          </Field.Description>
        )}
        {errorMessage && (
          <Field.ErrorMessage {...errorMessageProps}>
            {errorMessage}
          </Field.ErrorMessage>
        )}
      </Field>
    );
  },
);
