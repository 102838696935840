import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Theme } from "../models/Theme";
import { RootState } from "./reducers";

export type ThemeState = { theme: Theme | null };

export const themeSlice = createSlice({
  name: "theme",
  initialState: { theme: null } as ThemeState,
  reducers: {
    setTheme: (state, action: PayloadAction<Theme>) => {
      state.theme = action.payload;
    },
    unsetTheme: (state) => {
      state.theme = null;
    },
  },
});
export default themeSlice.reducer;

export const { setTheme, unsetTheme } = themeSlice.actions;

export const selectThemeState = (rootState: RootState) => rootState.theme;

export const selectTheme = (rootState: RootState) =>
  selectThemeState(rootState).theme;
