import { appApi } from "../../app/api/public/app";
import { AppConfig } from "../../app/models/AppConfig";
import { A } from "../shared/A";
import { Ad } from "../shared/Ad";

export const BannersLandscape = () => {
  const {
    currentData: { banner },
  } = appApi.endpoints.getAppConfig.useQueryState() as {
    currentData: AppConfig;
  };

  return banner ? (
    <Ad
      aria-label={banner.label}
      as={A}
      dataLayer={{
        eventAction: "clic",
        eventCategory: "pre-home",
        eventLabel: banner.label,
      }}
      href={banner.url}
      style={{ background: banner.background }}
      variant="landscape"
    >
      <img alt="" loading="lazy" src={banner.desktop} />
    </Ad>
  ) : null;
};
