import { useTranslation } from "react-i18next";

import { A } from "../shared/A";

import "./CompanyNav.scss";

const classNamePrefix = "CompanyNav";

export const CompanyNav = () => {
  const { t } = useTranslation();
  const { label, links } = t("features.company.CompanyNav") as unknown as {
    label: string;
    links: {
      key: "grandInvestisseurPrive" | "particulier" | "propertyManagement";
      current?: true;
      href: string;
      label: string;
      text: string;
      title: string;
    }[];
  };

  return (
    <nav aria-label={label} className={classNamePrefix}>
      <ul>
        {links.map((link) => (
          <li key={link.key}>
            <A
              aria-current={link.current}
              aria-label={link.label}
              dataLayer={{
                eventAction: "clic",
                eventCategory: "pre-home",
                eventLabel: link.key,
              }}
              href={link.href}
              title={link.title}
            >
              {link.text}
            </A>
          </li>
        ))}
      </ul>
    </nav>
  );
};
