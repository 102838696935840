import { useTranslation } from "react-i18next";

import { Head as SharedHead } from "@epc-front/shared/components/Head";

type HeadProps = { title: string };

export const Head = (props: HeadProps) => {
  const { title } = props;

  const { t } = useTranslation();
  const { root } = t("features.shared.Head") as unknown as { root: string };

  return (
    <SharedHead>
      <title>{title ? `${title} - ${root}` : root}</title>
    </SharedHead>
  );
};
