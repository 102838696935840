import { AriaTextFieldOptions, useTextField } from "@react-aria/textfield";
import { useObjectRef } from "@react-aria/utils";
import classNames from "classnames";
import React from "react";

import { Field } from "./Field";

const classNamePrefix = "PasswordField";

type PasswordFieldProps = AriaTextFieldOptions<"input"> & {
  className?: string;
  errorMessage?: React.ReactNode;
  markerLabel?: string;
};

export const PasswordField = React.forwardRef(
  (props: PasswordFieldProps, _ref: React.ForwardedRef<HTMLInputElement>) => {
    const {
      className,
      description,
      errorMessage,
      isInvalid = !!props.errorMessage,
      label,
      markerLabel = "Afficher/Cacher",
      type = "text",
    } = props;

    const ref = useObjectRef(_ref);
    const { descriptionProps, errorMessageProps, inputProps, labelProps } =
      useTextField({ ...props, type, isInvalid }, ref);

    const [isPassword, setIsPassword] = React.useState(true);

    return (
      <Field
        className={classNames(className, classNamePrefix)}
        data-hidden={isPassword}
      >
        {label && <Field.Label {...labelProps}>{label}</Field.Label>}
        <div>
          <input
            ref={ref}
            {...inputProps}
            type={isPassword ? "password" : type}
          />
          <button
            aria-label={markerLabel}
            type="button"
            onClick={() => setIsPassword(!isPassword)}
          />
        </div>
        {description && (
          <Field.Description {...descriptionProps}>
            {description}
          </Field.Description>
        )}
        {errorMessage && (
          <Field.ErrorMessage {...errorMessageProps}>
            {errorMessage}
          </Field.ErrorMessage>
        )}
      </Field>
    );
  },
);
