import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { privateApi } from "../api/private";
import { publicApi } from "../api/public";
import rootReducer, { RootState } from "./reducers";

const createStore = (preloadedState?: any) =>
  configureStore({
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(privateApi.middleware)
        .concat(publicApi.middleware),
    preloadedState,
    reducer: rootReducer,
  });
const store = createStore();
setupListeners(store.dispatch);
export default store;

export type AppDispatch = typeof store.dispatch;

export type AppThunkAPI = { dispatch: AppDispatch; state: RootState };

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
