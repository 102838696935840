import { useTranslation } from "react-i18next";

import { Context, context } from "../../app/services/context";
import { A } from "../shared/A";
import { Markdown } from "../shared/Markdown";

import "./AppStoresPush.scss";
import AppStoreIcon from "./AppStoresPushAppStoreIcon.png";
import GooglePlayIcon from "./AppStoresPushGooglePlayIcon.png";

const classNamePrefix = "AppStoresPush";

export const AppStoresPush = () => {
  const { t } = useTranslation();
  const { nav, text } = t("features.app.AppStoresPush") as unknown as {
    nav: {
      label: string;
      links: { key: "appStore" | "googlePlay"; href: string; label: string }[];
    };
    text: string;
  };

  const additionalLinksProps = {
    appStore: { icon: AppStoreIcon },
    googlePlay: { icon: GooglePlayIcon },
  };

  return context === Context.BROWSER ? (
    <div className={classNamePrefix}>
      <div className={`${classNamePrefix}-text`}>
        <Markdown>{text}</Markdown>
      </div>
      <nav aria-label={nav.label} className={`${classNamePrefix}-nav`}>
        <ul>
          {nav.links.map((link) => {
            const additionalLinkProps = additionalLinksProps[link.key];
            return (
              <li key={link.key}>
                <A
                  aria-label={link.label}
                  dataLayer={{
                    eventAction: "clic",
                    eventCategory: "pre-home",
                    eventLabel: link.key,
                  }}
                  href={link.href}
                >
                  <img
                    alt=""
                    height="53"
                    src={additionalLinkProps.icon}
                    width="150"
                  />
                </A>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  ) : null;
};
