import React from "react";

import { Result as SharedResult } from "@epc-front/shared/components/Result";

import { HttpStatus } from "../../app/constants/HttpStatus";
import { Markdown } from "./Markdown";

import Error404Image from "./ResultError404Image.png";
import Error500Image from "./ResultError500Image.png";

type ResultProps = Omit<React.ComponentProps<typeof SharedResult>, "image"> & {
  status?: HttpStatus.NOT_FOUND | HttpStatus.INTERNAL_SERVER_ERROR;
};

export const Result = React.forwardRef(
  (props: ResultProps, ref?: React.ForwardedRef<HTMLDivElement>) => {
    const { text, title, status, ...restProps } = props;

    const image =
      status &&
      {
        [HttpStatus.NOT_FOUND]: Error404Image,
        [HttpStatus.INTERNAL_SERVER_ERROR]: Error500Image,
      }[status];

    return (
      <SharedResult
        ref={ref}
        {...restProps}
        data-status={status}
        image={image && <img alt="" src={image} />}
        text={<Markdown>{text}</Markdown>}
        title={<Markdown>{title}</Markdown>}
      />
    );
  },
);
