import {
  AriaCheckboxGroupItemProps,
  AriaCheckboxGroupProps,
  useCheckboxGroup,
  useCheckboxGroupItem,
} from "@react-aria/checkbox";
import { useObjectRef } from "@react-aria/utils";
import {
  CheckboxGroupState,
  useCheckboxGroupState,
} from "@react-stately/checkbox";
import classNames from "classnames";
import React from "react";

import { Field } from "./Field";

const classNamePrefix = "CheckboxGroup";

const CheckboxGroupContext = React.createContext({} as CheckboxGroupState);

type CheckboxGroupRootProps = AriaCheckboxGroupProps & {
  children: React.ReactNode;
  className?: string;
  errorMessage?: React.ReactNode;
  variant?: "block";
};

const CheckboxGroupRoot = React.forwardRef(
  (props: CheckboxGroupRootProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const {
      children,
      className,
      description,
      errorMessage,
      isInvalid = !!props.errorMessage,
      label,
      variant,
    } = props;

    const state = useCheckboxGroupState(props);
    const { descriptionProps, errorMessageProps, groupProps, labelProps } =
      useCheckboxGroup({ ...props, isInvalid }, state);

    return (
      <Field
        ref={ref}
        {...groupProps}
        className={classNames(className, classNamePrefix)}
        data-variant={variant}
      >
        <div>
          <div>
            <Field.Label {...labelProps} as="div">
              {label}
            </Field.Label>
            {description && (
              <Field.Description {...descriptionProps}>
                {description}
              </Field.Description>
            )}
          </div>
          <div>
            <ul>
              <CheckboxGroupContext.Provider value={state}>
                {children}
              </CheckboxGroupContext.Provider>
            </ul>
          </div>
        </div>
        {errorMessage && (
          <Field.ErrorMessage {...errorMessageProps}>
            {errorMessage}
          </Field.ErrorMessage>
        )}
      </Field>
    );
  },
);

type CheckboxGroupItemProps = AriaCheckboxGroupItemProps;

const CheckboxGroupItem = React.forwardRef(
  (
    props: CheckboxGroupItemProps,
    _ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const { children } = props;

    const state = React.useContext(CheckboxGroupContext);
    const ref = useObjectRef(_ref);
    const { inputProps } = useCheckboxGroupItem(props, state, ref);

    return (
      <li>
        <Field.Label>
          <input ref={ref} {...inputProps} />
          <span>{children}</span>
        </Field.Label>
      </li>
    );
  },
);

export const CheckboxGroup = Object.assign(CheckboxGroupRoot, {
  Item: CheckboxGroupItem,
});
