import { privateApi } from ".";
import { User } from "../../models/User";
import { setCurrentUser } from "../../store/auth";

const baseUrl = "/user";

export const currentUserApi = privateApi.injectEndpoints({
  endpoints: (builder) => ({
    acceptTermsOfService: builder.mutation<void, void>({
      query: () => ({
        url: `${baseUrl}/accept-terms-of-service`,
        method: "POST",
      }),
      onQueryStarted: (_arg, queryLifecycleApi) => {
        const { currentUser } = (queryLifecycleApi.getState() as any).auth;
        queryLifecycleApi.dispatch(
          setCurrentUser({ ...currentUser, hasAcceptedTermsOfService: true }),
        );
      },
    }),
    acceptTermsOfServices: builder.mutation<void, { hasAccepted: boolean }>({
      query: (arg) => ({
        url: `${baseUrl}/terms-of-services/inside`,
        method: "POST",
        data: arg,
      }),
      onQueryStarted: (arg, queryLifecycleApi) => {
        const { currentUser } = (queryLifecycleApi.getState() as any).auth;
        queryLifecycleApi.dispatch(
          setCurrentUser({
            ...currentUser,
            termsOfServices: {
              ...currentUser.termsOfServices,
              inside: { ...currentUser.termsOfServices.inside, ...arg },
            },
          }),
        );
      },
    }),
    getCurrentUser: builder.query<User, void>({
      query: () => ({ url: `${baseUrl}/info`, method: "GET" }),
      providesTags: () => [{ type: "CurrentUser" }],
      onQueryStarted: async (_arg, queryLifecycleApi) => {
        try {
          const response = await queryLifecycleApi.queryFulfilled;
          queryLifecycleApi.dispatch(setCurrentUser(response.data));
        } catch {}
      },
    }),
    signInHistory: builder.mutation<void, void>({
      query: () => ({ url: `${baseUrl}/sign-in-history`, method: "POST" }),
    }),
  }),
});

export const {
  useAcceptTermsOfServiceMutation,
  useAcceptTermsOfServicesMutation,
} = currentUserApi;
