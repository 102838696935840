import { MarkdownToJSX, compiler } from "markdown-to-jsx";
import React from "react";

type MarkdownProps = {
  children: React.ReactNode;
  options?: Omit<MarkdownToJSX.Options, "wrapper">;
};

export const Markdown = (props: MarkdownProps) => {
  const { children, options } = props;
  return (
    <>
      {typeof children === "string"
        ? compiler(children, { wrapper: null, ...options })
        : children}
    </>
  );
};
