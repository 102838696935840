import axios from "axios";

import { publicApi } from ".";

const baseUrl = "/legals";

export const legalsApi = publicApi.injectEndpoints({
  endpoints: (builder) => ({
    getAccessibility: builder.query<string, void>({
      queryFn: async () => {
        const response = await axios({ url: "/locales/fr.accessibility.md" });
        return { data: response.data };
      },
    }),
    getLegalNotice: builder.query<string, void>({
      query: () => ({ url: `${baseUrl}/legal-notice`, method: "GET" }),
    }),
    getTermsOfService: builder.query<string, void>({
      query: () => ({ url: `${baseUrl}/terms-of-service`, method: "GET" }),
    }),
  }),
});

export const {
  useLazyGetAccessibilityQuery,
  useLazyGetLegalNoticeQuery,
  useLazyGetTermsOfServiceQuery,
} = legalsApi;
