import { Params, generatePath } from "react-router-dom";

import { RouteKey, routes } from "../config/routes";

function getPath(routeKey: RouteKey) {
  return routes[routeKey];
}

function getRelativePath(from: RouteKey, to: RouteKey) {
  return getPath(to).replace(getPath(from).replace("/*", ""), "");
}

function getTo(routeKey: RouteKey, params?: Params<string>) {
  return generatePath(routes[routeKey].replace("/*", ""), params);
}

function getRelativeTo(from: RouteKey, to: RouteKey) {
  return routes[to]
    .replace("/*", "")
    .replace(getPath(from).replace("*", ""), "");
}

export const router = { getPath, getRelativePath, getTo, getRelativeTo };
