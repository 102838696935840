import { AppConfigApiKeys } from "../models/AppConfig";

let _value = {} as AppConfigApiKeys;

function configure(value: AppConfigApiKeys) {
  _value = value;
}

export const apiKeys = {
  get value() {
    return _value;
  },
  configure,
};
