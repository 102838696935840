import classNames from "classnames";
import React from "react";

const classNamePrefix = "FieldGroup";

type FieldGroupProps = React.HTMLAttributes<HTMLDivElement> & {
  columnCount?: 1 | 2 | 3 | 4;
  description?: React.ReactNode;
  errorMessage?: React.ReactNode;
  label?: React.ReactNode;
};

export const FieldGroup = React.forwardRef(
  (props: FieldGroupProps, ref?: React.ForwardedRef<HTMLDivElement>) => {
    const {
      children,
      className,
      columnCount,
      description,
      errorMessage,
      label,
      ...restProps
    } = props;
    return (
      <div
        ref={ref}
        {...restProps}
        className={classNames(className, classNamePrefix)}
        data-column-count={columnCount}
      >
        {label && <div className={`${classNamePrefix}-label`}>{label}</div>}
        <div className={`${classNamePrefix}-content`}>{children}</div>
        {description && (
          <div className={`${classNamePrefix}-description`}>{description}</div>
        )}
        {errorMessage && (
          <div className={`${classNamePrefix}-errorMessage`} role="alert">
            {errorMessage}
          </div>
        )}
      </div>
    );
  },
);
