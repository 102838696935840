export enum Context {
  BROWSER = "BROWSER",
  STANDALONE = "STANDALONE",
}

export const context =
  (window.navigator as any).standalone ||
  document.referrer.includes("android-app://") ||
  ["fullscreen", "minimal-ui", "standalone"].some(
    (displayMode) =>
      window.matchMedia(`(display-mode: "${displayMode}")`).matches,
  )
    ? Context.STANDALONE
    : Context.BROWSER;
