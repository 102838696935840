import React from "react";
import { useTranslation } from "react-i18next";

import { useConfirmSignUpQuery } from "../../app/api/public/account";
import { HttpStatus } from "../../app/constants/HttpStatus";
import { Markdown } from "../shared/Markdown";
import { SignUpState, SignUpStateType } from "./SignUpState";

type SignUpConfirmSignUpFormProps = {
  session: { code: string; username: string };
  onChange: React.Dispatch<React.SetStateAction<SignUpState>>;
};

export const SignUpConfirmSignUpForm = (
  props: SignUpConfirmSignUpFormProps,
) => {
  const { session, onChange } = props;

  const { error: _error, isSuccess: isSubmitSuccessful } =
    useConfirmSignUpQuery(session);

  const { t } = useTranslation();
  const { errorMessage, loadingMessage, successMessage } = t(
    "features.account.SignUpConfirmSignUpForm",
  ) as unknown as {
    errorMessage: Record<HttpStatus, string>;
    loadingMessage: string;
    successMessage: string;
  };

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      onChange({
        step: SignUpStateType.RESULT,
        message: successMessage,
        session: { username: session.username },
      });
    }
  }, [isSubmitSuccessful, onChange, session.username, successMessage]);

  React.useEffect(() => {
    if (_error) {
      const error = _error as { status: keyof typeof errorMessage };
      onChange({
        step: SignUpStateType.RESULT,
        message:
          errorMessage[error.status] ??
          errorMessage[HttpStatus.INTERNAL_SERVER_ERROR],
        session: { username: session.username },
      });
    }
  }, [_error, errorMessage, onChange, session.username]);

  return (
    <div role="status">
      <Markdown>{loadingMessage}</Markdown>
    </div>
  );
};
