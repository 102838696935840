import { apiKeys } from "./apiKeys";

declare const window: Window & { grecaptcha?: any };

function execute(action: string) {
  return new Promise<string | undefined>((resolve) => {
    if (window.grecaptcha) {
      window.grecaptcha.ready(async () => {
        try {
          const token = await window.grecaptcha.execute(
            apiKeys.value.reCaptcha,
            { action },
          );
          resolve(token);
        } catch (error) {
          resolve(undefined);
        }
      });
    } else {
      resolve(undefined);
    }
  });
}

export const reCaptcha = { execute };
