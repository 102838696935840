import { Account } from "../../app/models/Account";

export type SignUpState =
  | ConfirmAccountSignUpState
  | ConfirmEmailSignUpState
  | ConfirmSignUpSignUpState
  | GetAccountWithCharacterCodeSignUpState
  | GetAccountWithEmailSignUpState
  | ResultSignUpState;

export enum SignUpStateType {
  CONFIRM_ACCOUNT = "CONFIRM_ACCOUNT",
  CONFIRM_EMAIL = "CONFIRM_EMAIL",
  CONFIRM_SIGN_UP = "CONFIRM_SIGN_UP",
  GET_ACCOUNT_WITH_CHARACTER_CODE = "GET_ACCOUNT_WITH_CHARACTER_CODE",
  GET_ACCOUNT_WITH_EMAIL = "GET_ACCOUNT_WITH_EMAIL",
  RESULT = "RESULT",
}

export type ConfirmAccountSignUpState = {
  step: SignUpStateType.CONFIRM_ACCOUNT;
  account: Account;
};

export type ConfirmEmailSignUpState = {
  step: SignUpStateType.CONFIRM_EMAIL;
  account: Account;
};

export type ConfirmSignUpSignUpState = {
  step: SignUpStateType.CONFIRM_SIGN_UP;
  session: { code: string; username: string };
};

export type GetAccountWithCharacterCodeSignUpState = {
  step: SignUpStateType.GET_ACCOUNT_WITH_CHARACTER_CODE;
};

export type GetAccountWithEmailSignUpState = {
  step: SignUpStateType.GET_ACCOUNT_WITH_EMAIL;
};

export type ResultSignUpState = {
  step: SignUpStateType.RESULT;
  message: string;
  session: { email?: string; username?: string };
};
