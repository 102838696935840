import classNames from "classnames";
import React from "react";

import locales from "./Spinner.fr.json";

const classNamePrefix = "Spinner";

type SpinnerProps = React.HTMLAttributes<HTMLDivElement>;

export const Spinner = React.forwardRef(
  (props: SpinnerProps, ref?: React.ForwardedRef<HTMLDivElement>) => {
    const {
      "aria-label": ariaLabel = locales.label,
      className,
      role = "status",
      ...restProps
    } = props;
    return (
      <div
        ref={ref}
        {...restProps}
        aria-label={ariaLabel}
        className={classNames(className, classNamePrefix)}
        role={role}
      />
    );
  },
);
