import { useTranslation } from "react-i18next";

import { router } from "../app/services/router";
import { AccountSignInForm } from "../features/account/AccountSignInForm";
import { A } from "../features/shared/A";
import { Button } from "../features/shared/Button";
import { Separator } from "../features/shared/Separator";
import { SignUpPage } from "./SignUpPage";
import { AuthenticationPage } from "./shared/AuthenticationPage";

export const SignInPage = () => {
  const { t } = useTranslation();
  const { meta, nav, title } = t("pages.SignInPage") as unknown as {
    meta: { title: string };
    nav: { forgotPassword: { text: string }; signUp: { text: string } };
    title: string;
  };

  return (
    <>
      <AuthenticationPage
        dataLayer={{
          categorie_page: "homepage",
          nom_page: "pre-home",
          virtualpagetitle: "Homepage déconnectée",
        }}
        meta={meta}
        title={title}
      >
        <AccountSignInForm />
        <Button
          as={A}
          dataLayer={{
            eventAction: "clic",
            eventCategory: "pre_home",
            eventLabel: "mot_de_passe_oublie",
          }}
          href={router.getTo("authenticationForgotPassword")}
          icon="next"
          variant="text"
        >
          {nav.forgotPassword.text}
        </Button>
        <Separator variant="or" />
        <Button
          as={A}
          dataLayer={{
            eventAction: "clic",
            eventCategory: "pre_home",
            eventLabel: "creer_un_compte",
          }}
          href={router.getTo("authenticationSignUp")}
          variant="action"
        >
          {nav.signUp.text}
        </Button>
      </AuthenticationPage>
      <SignUpPage />
    </>
  );
};
