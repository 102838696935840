import classNames from "classnames";
import React from "react";
import {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
} from "react-polymorphic-types";

const classNamePrefix = "CloseButton";

type CloseButtonOwnProps = {};
const closeButtonDefaultElement = "button";

export const CloseButton: PolymorphicForwardRefExoticComponent<
  CloseButtonOwnProps,
  typeof closeButtonDefaultElement
> = React.forwardRef(
  <TElementType extends React.ElementType = typeof closeButtonDefaultElement>(
    props: PolymorphicPropsWithoutRef<CloseButtonOwnProps, TElementType>,
    ref?: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    const {
      as: Tag = closeButtonDefaultElement,
      children,
      className,
      ...restProps
    } = props;
    return (
      <Tag
        ref={ref}
        {...restProps}
        className={classNames(className, classNamePrefix)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden
          fill="currentColor"
          focusable={false}
          height="20"
          stroke="currentColor"
          viewBox="0 0 20 20"
          width="20"
        >
          <path
            d="M19.1776 17.7635C19.5681 18.154 19.5681 18.7872 19.1776 19.1777C18.7871 19.5682 18.1539 19.5682 17.7634 19.1777L9.99989 11.4142L2.2364 19.1777C1.84588 19.5682 1.21271 19.5682 0.82219 19.1777C0.431666 18.7872 0.431666 18.154 0.82219 17.7635L8.58567 10L0.82219 2.23653C0.431666 1.846 0.431666 1.21284 0.82219 0.822312C1.21271 0.431788 1.84588 0.431788 2.2364 0.822312L9.99989 8.58579L17.7634 0.822312C18.1539 0.431788 18.7871 0.431788 19.1776 0.822312C19.5681 1.21284 19.5681 1.846 19.1776 2.23653L11.4141 10L19.1776 17.7635Z"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        {children}
      </Tag>
    );
  },
);
