import * as RadixUiSeparator from "@radix-ui/react-separator";
import classNames from "classnames";
import React from "react";

const classNamePrefix = "Separator";

type SeparatorProps = RadixUiSeparator.SeparatorProps;

export const Separator = React.forwardRef(
  (props: SeparatorProps, ref?: React.ForwardedRef<HTMLDivElement>) => {
    const { children, className, ...restProps } = props;
    return (
      <RadixUiSeparator.Root
        ref={ref}
        {...restProps}
        className={classNames(className, classNamePrefix)}
      >
        {children && <div>{children}</div>}
      </RadixUiSeparator.Root>
    );
  },
);
