import { useTranslation } from "react-i18next";

import { A } from "../shared/A";
import { Button } from "../shared/Button";

export const CompanyAnchor = () => {
  const { t } = useTranslation();
  const { href, label, text } = t(
    "features.company.CompanyAnchor",
  ) as unknown as { href: string; label: string; text: string };

  return (
    <Button
      aria-label={label}
      as={A}
      dataLayer={{
        eventAction: "clic",
        eventCategory: "pre-home",
        eventLabel: "lien-retour-nfr",
      }}
      href={href}
      icon="next"
      variant="text"
    >
      {text}
    </Button>
  );
};
