import { AriaTextFieldOptions, useTextField } from "@react-aria/textfield";
import { useObjectRef } from "@react-aria/utils";
import classNames from "classnames";
import React from "react";

import { Field } from "./Field";

const classNamePrefix = "TextField";

type TextFieldProps = AriaTextFieldOptions<"input"> & {
  className?: string;
  errorMessage?: React.ReactNode;
};

export const TextField = React.forwardRef(
  (props: TextFieldProps, _ref: React.ForwardedRef<HTMLInputElement>) => {
    const {
      className,
      description,
      errorMessage,
      isInvalid = !!props.errorMessage,
      label,
    } = props;

    const ref = useObjectRef(_ref);
    const { descriptionProps, errorMessageProps, inputProps, labelProps } =
      useTextField({ ...props, isInvalid }, ref);

    return (
      <Field className={classNames(className, classNamePrefix)}>
        {label && <Field.Label {...labelProps}>{label}</Field.Label>}
        <input ref={ref} {...inputProps} />
        {description && (
          <Field.Description {...descriptionProps}>
            {description}
          </Field.Description>
        )}
        {errorMessage && (
          <Field.ErrorMessage {...errorMessageProps}>
            {errorMessage}
          </Field.ErrorMessage>
        )}
      </Field>
    );
  },
);
