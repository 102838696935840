import React from "react";

import { AppRoutes } from "./AppRoutes";
import { AuthenticationApp } from "./AuthenticationApp";
import { useAutoSignInQuery } from "./app/api/public/auth";
import { useAppSelector } from "./app/store";
import { selectCurrentUser } from "./app/store/auth";
import { ScrollRestorer } from "./features/core/ScrollRestorer";
import { Theme } from "./features/core/Theme";
import { Tracking } from "./features/core/Tracking";
import { InformationNoticeDialog } from "./features/informationNotice/InformationNoticeDialog";
import { SessionDialog } from "./features/session/SessionDialog";
import { Error500Page } from "./pages/Error500Page";

const MainApp = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./MainApp").then((module) => ({
    default: module.MainApp,
  })),
);

export const App = () => {
  const { isError, isLoading, isSuccess } = useAutoSignInQuery();

  React.useEffect(() => {
    if (!isLoading) {
      document.getElementById("splash-screen")?.remove();
    }
  }, [isLoading]);

  return (
    <>
      {isSuccess && (
        <>
          <AppLayout />
          <AppRoutes />
          <ScrollRestorer />
          <Theme />
          <Tracking />
          <InformationNoticeDialog />
          <SessionDialog />
        </>
      )}
      {isError && <Error500Page />}
    </>
  );
};

const AppLayout = () => {
  const currentUser = useAppSelector(selectCurrentUser);

  return currentUser?.hasAcceptedTermsOfService ? (
    <React.Suspense fallback={null}>
      <MainApp />
    </React.Suspense>
  ) : (
    <AuthenticationApp />
  );
};
