import * as RadixUiDialog from "@radix-ui/react-dialog";
import classNames from "classnames";
import React from "react";

import { CloseButton } from "./CloseButton";
import { Overlay } from "./Overlay";

const classNamePrefix = "Dialog";

type DialogRootProps = RadixUiDialog.DialogProps;

const DialogRoot = (props: DialogRootProps) => (
  <RadixUiDialog.Root {...props} />
);

type DialogContentProps = Omit<RadixUiDialog.DialogContentProps, "title"> & {
  action?: React.ReactNode;
  closeLabel?: string;
  icon?: React.ReactNode;
  image?: React.ReactNode;
  scrollRef?: React.ForwardedRef<HTMLDivElement>;
  search?: React.ReactNode;
  size?: "small" | "medium" | "large";
  title: React.ReactNode;
};

const DialogContent = React.forwardRef(
  (props: DialogContentProps, ref?: React.ForwardedRef<HTMLDivElement>) => {
    const {
      action,
      "aria-describedby": ariaDescribedby = undefined,
      children,
      className,
      closeLabel = "Fermer",
      icon,
      image,
      scrollRef,
      search,
      size,
      title,
      onScroll,
      ...restProps
    } = props;
    return (
      <RadixUiDialog.Portal>
        <RadixUiDialog.Overlay asChild>
          <Overlay />
        </RadixUiDialog.Overlay>
        <RadixUiDialog.Content
          ref={ref}
          {...restProps}
          aria-describedby={ariaDescribedby}
          className={classNames(className, classNamePrefix)}
          data-icon={icon ? true : undefined}
          data-image={image ? true : undefined}
          data-size={size}
        >
          {image && <div className={`${classNamePrefix}-image`}>{image}</div>}
          <div className={`${classNamePrefix}-content`}>
            <div className={`${classNamePrefix}-header`}>
              {icon && <div className={`${classNamePrefix}-icon`}>{icon}</div>}
              <RadixUiDialog.Title asChild>
                <h1 className={`${classNamePrefix}-title`}>{title}</h1>
              </RadixUiDialog.Title>
            </div>
            {search && (
              <div className={`${classNamePrefix}-search`}>{search}</div>
            )}
            <div
              ref={scrollRef}
              className={`${classNamePrefix}-body`}
              onScroll={onScroll}
            >
              {children}
            </div>
            {action && (
              <div className={`${classNamePrefix}-footer`}>{action}</div>
            )}
          </div>
          <RadixUiDialog.DialogClose asChild>
            <CloseButton
              aria-label={closeLabel}
              className={`${classNamePrefix}-close`}
            />
          </RadixUiDialog.DialogClose>
        </RadixUiDialog.Content>
      </RadixUiDialog.Portal>
    );
  },
);

type DialogTriggerProps = RadixUiDialog.DialogTriggerProps;

const DialogTrigger = React.forwardRef(
  (props: DialogTriggerProps, ref?: React.ForwardedRef<HTMLButtonElement>) => {
    const { asChild = true, ...restProps } = props;
    return <RadixUiDialog.Trigger ref={ref} {...restProps} asChild={asChild} />;
  },
);

export const Dialog = Object.assign(DialogRoot, {
  Content: DialogContent,
  Trigger: DialogTrigger,
});
