import classNames from "classnames";
import React from "react";
import {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
} from "react-polymorphic-types";

const classNamePrefix = "Ad";

type AdOwnProps = { variant?: "banner" | "landscape" };
const adDefaultElement = "a";

export const Ad: PolymorphicForwardRefExoticComponent<
  AdOwnProps,
  typeof adDefaultElement
> = React.forwardRef(
  <TElementType extends React.ElementType = typeof adDefaultElement>(
    props: PolymorphicPropsWithoutRef<AdOwnProps, TElementType>,
    ref?: React.ForwardedRef<HTMLAnchorElement>,
  ) => {
    const {
      as: Tag = adDefaultElement,
      className,
      variant,
      ...restProps
    } = props;
    return (
      <Tag
        ref={ref}
        {...restProps}
        className={classNames(className, classNamePrefix)}
        data-variant={variant}
      />
    );
  },
);
