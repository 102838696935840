import { useTranslation } from "react-i18next";

import { A } from "../shared/A";
import { Button } from "../shared/Button";
import { Markdown } from "../shared/Markdown";

import "./AssistancePush.scss";

const classNamePrefix = "AssistancePush";

export const AssistancePush = () => {
  const { t } = useTranslation();
  const { action, text } = t(
    "features.assistance.AssistancePush",
  ) as unknown as { action: { href: string; text: string }; text: string };

  return (
    <div className={classNamePrefix}>
      <div className={`${classNamePrefix}-action`}>
        <Button
          as={A}
          dataLayer={{
            eventAction: "clic",
            eventCategory: "pre-home",
            eventLabel: "lien-faq-nfr",
          }}
          href={action.href}
        >
          {action.text}
        </Button>
      </div>
      <div className={`${classNamePrefix}-text`}>
        <Markdown>{text}</Markdown>
      </div>
    </div>
  );
};
