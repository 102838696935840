import * as RadixUiAlertDialog from "@radix-ui/react-alert-dialog";
import classNames from "classnames";
import React from "react";

import { Overlay } from "./Overlay";

const classNamePrefix = "AlertDialog";

type AlertDialogRootProps = RadixUiAlertDialog.AlertDialogProps;

const AlertDialogRoot = (props: AlertDialogRootProps) => (
  <RadixUiAlertDialog.Root {...props} />
);

type AlertDialogContentProps = Omit<
  RadixUiAlertDialog.AlertDialogContentProps,
  "title"
> & {
  action?: React.ReactNode;
  cancel?: React.ReactNode;
  icon?: React.ReactNode;
  image?: React.ReactNode;
  scrollRef?: React.ForwardedRef<HTMLDivElement>;
  text: React.ReactNode;
  title: React.ReactNode;
};

const AlertDialogContent = React.forwardRef(
  (
    props: AlertDialogContentProps,
    ref?: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const {
      action,
      cancel,
      children,
      className,
      icon,
      image,
      scrollRef,
      text,
      title,
      onScroll,
      ...restProps
    } = props;
    return (
      <RadixUiAlertDialog.Portal>
        <RadixUiAlertDialog.Overlay asChild>
          <Overlay />
        </RadixUiAlertDialog.Overlay>
        <RadixUiAlertDialog.Content
          ref={ref}
          {...restProps}
          className={classNames(className, classNamePrefix)}
          data-icon={icon ? true : undefined}
          data-image={image ? true : undefined}
        >
          {image && <div className={`${classNamePrefix}-image`}>{image}</div>}
          <div className={`${classNamePrefix}-content`}>
            <div className={`${classNamePrefix}-header`}>
              {icon && <div className={`${classNamePrefix}-icon`}>{icon}</div>}
              <RadixUiAlertDialog.Title asChild>
                <h1 className={`${classNamePrefix}-title`}>{title}</h1>
              </RadixUiAlertDialog.Title>
            </div>
            <RadixUiAlertDialog.Description asChild>
              <div
                ref={scrollRef}
                className={`${classNamePrefix}-body`}
                onScroll={onScroll}
              >
                {text}
                {children}
              </div>
            </RadixUiAlertDialog.Description>
            {(action || cancel) && (
              <div className={`${classNamePrefix}-footer`}>
                {cancel && (
                  <RadixUiAlertDialog.Cancel asChild>
                    {cancel}
                  </RadixUiAlertDialog.Cancel>
                )}
                {action}
              </div>
            )}
          </div>
        </RadixUiAlertDialog.Content>
      </RadixUiAlertDialog.Portal>
    );
  },
);

type AlertDialogTriggerProps = RadixUiAlertDialog.AlertDialogTriggerProps;

const AlertDialogTrigger = React.forwardRef(
  (
    props: AlertDialogTriggerProps,
    ref?: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    const { asChild = true, ...restProps } = props;
    return (
      <RadixUiAlertDialog.Trigger ref={ref} {...restProps} asChild={asChild} />
    );
  },
);

export const AlertDialog = Object.assign(AlertDialogRoot, {
  Content: AlertDialogContent,
  Trigger: AlertDialogTrigger,
});
