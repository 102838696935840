import React from "react";
import { useTranslation } from "react-i18next";

import { Separator as SharedSeparator } from "@epc-front/shared/components/Separator";

type SeparatorProps = Omit<
  React.ComponentProps<typeof SharedSeparator>,
  "children"
> & { variant: "empty" | "or" };

export const Separator = React.forwardRef(
  (props: SeparatorProps, ref?: React.ForwardedRef<HTMLDivElement>) => {
    const { variant, ...restProps } = props;

    const { t } = useTranslation();
    const { text } = t("features.shared.Separator", { variant }) as unknown as {
      text: string;
    };

    return (
      <SharedSeparator ref={ref} {...restProps} data-variant={variant}>
        {text}
      </SharedSeparator>
    );
  },
);
